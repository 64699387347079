import React, { useEffect, useState } from "react";
import { NavLink, useParams } from "react-router-dom";
import InnerBanner from "../Section/InnerBanner";
import { Col, Container, Row } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import { fetchBlogData } from "../../redux/slice/blogSlice";
import { BASE_URL, monthHash } from "../../VariableHandler/handler";
import RecentBlog from "../Section/blog/RecentBlog";

export default function BlogDetail() {
    const { id } = useParams();
    const dispatch = useDispatch();
    const [blogDetail, setBlogDetail] = useState(null);
    const { data: blog } = useSelector((state) => state.blog);

    useEffect(() => {
        dispatch(fetchBlogData());
    }, [dispatch]);

    useEffect(() => {
        if (blog) {
            const detail = blog.find(post => post.id === parseInt(id));
            setBlogDetail(detail);
        }
    }, [blog, id]);

    const formatDate = (dateString) => {
        const date = dateString;
        const day = date.slice(8, 10);
        const month = date.slice(5, 7);
        const monthString = monthHash(month);
        const year = date.slice(0, 4);
        return `${day} ${monthString} ${year}`;
    };

    if (!blogDetail) return <p>Loading...</p>;

    // Get the index of the current blog post
    const currentIndex = blog.findIndex(post => post.id === parseInt(id));

    // Calculate the previous and next post indices
    const prevPost = blog[currentIndex - 1];
    const nextPost = blog[currentIndex + 1];

    return (
        <React.Fragment>
            <InnerBanner title="Blog detail" />
            <section className="section padding_layout_1 blog_grid">
                <Container>
                    <Row>
                        <Col lg="8" md="8" sm="12" xs="12" className="pull-right">
                            <div className="full">
                                <div className="blog_section margin_bottom_0">
                                    <div className="blog_feature_img">
                                        <img
                                            className="img-responsive"
                                            src={`${BASE_URL}/uploads/blog/md/${blogDetail.image}`}
                                            alt="#"
                                        />
                                    </div>
                                    <div className="blog_feature_cantant">
                                        <p className="blog_head">{blogDetail.title}</p>
                                        <div className="post_info">
                                            <ul>
                                                <li>
                                                    <i className="fa fa-user" aria-hidden="true"></i> {blogDetail.author}
                                                </li>
                                                <li>
                                                    <i className="fa fa-calendar" aria-hidden="true"></i> {formatDate(blogDetail.created_at)}
                                                </li>
                                            </ul>
                                        </div>
                                        <p>{blogDetail.description}</p>
                                    </div>
                                    <div className="comment_section">
                                        {prevPost && (
                                            <div className="pull-left text_align_left">
                                                <div className="full">
                                                    <div className="preview_commt">
                                                        <NavLink className="comment_cantrol preview_commat" to={`/blog-detail/${prevPost.id}`}>
                                                            <span><i className="fa fa-angle-left"></i> Previous</span>
                                                        </NavLink>
                                                    </div>
                                                </div>
                                            </div>
                                        )}
                                        {nextPost && (
                                            <div className="pull-right text_align_right">
                                                <div className="full">
                                                    <div className="next_commt">
                                                        <NavLink className="comment_cantrol preview_commat" to={`/blog-detail/${nextPost.id}`}>
                                                            <span>Next <i className="fa fa-angle-right"></i></span>
                                                        </NavLink>
                                                    </div>
                                                </div>
                                            </div>
                                        )}
                                    </div>
                                </div>
                            </div>
                        </Col>
                        <Col lg="4" md="4" sm="12" xs="12">
                            <div className="side_bar">
                                <div className="side_bar_blog">
                                    <h4>ABOUT BLOG</h4>
                                    <p>Here's we covers the topics or news of computer repair, fire safety, CCTV appliances, and accounting software:</p>
                                </div>
                                <RecentBlog />
                            </div>
                        </Col>
                    </Row>
                </Container>
            </section>
        </React.Fragment>
    );
}
