import React, { useEffect } from "react";
import InnerBanner from "../Section/InnerBanner";
import { useDispatch, useSelector } from "react-redux";
import { fetchContactData } from "../../redux/slice/contactSlice";
import Loader from "../Elements/Loader";
import { fetchAddressData } from "../../redux/slice/addressSlice";
import { NavLink } from "react-router-dom";
import { fetchProfileData } from "../../redux/slice/profileSlice";
import ContactForm from "../Section/Contact/ContactForm";



export default function Contact(){

    const dispatch = useDispatch();
    const {data: address, loading, error} = useSelector((state) => state.address);
    const {data: contact } = useSelector((state) => state.contact);
    const {data: profile } = useSelector((state) => state.profile);

    useEffect(() => {
        dispatch(fetchContactData());
        dispatch(fetchAddressData());
        dispatch(fetchProfileData());
    }, [dispatch])

    if(loading) return <Loader/>
    if(error) return <p>Error: {error}</p>


    

    return(
        <React.Fragment>
            <InnerBanner title="Contact"/>

            <div className="section padding_layout_1">
                <div className="container">
                    <div className="row">                                     
                        <div className="col-xl-2 col-lg-2 col-md-12 col-sm-12 col-xs-12"></div>
                        <div className="col-xl-8 col-lg-8 col-md-12 col-sm-12 col-xs-12">
                            <div className="row">
                                <div className="full">
                                    <div className="col-lg-12 col-md-12 col-sm-12 col-xs-12">
                                    <div className="main_heading text_align_center">
                                        <h2>GET IN TOUCH</h2>
                                    </div>
                                    </div>
                                    <div className="contact_information">
                                    <div className="col-lg-4 col-md-4 col-sm-4 col-xs-12 adress_cont">
                                        <div className="information_bottom text_align_center">
                                            <div className="icon_bottom"> <i className="fa fa-road" aria-hidden="true"></i> </div>
                                            <div className="info_cont">
                                            {
                                                address.map((data, index) => (
                                                    <NavLink key={`contact-${index}`} to={data.googlemap_link} className="mb-3" target="_blank">
                                                        <h5>{data.address_title}</h5>
                                                        <h4>{data.address}</h4>
                                                        <p>{data.city} - {data.pincode}</p>
                                                    </NavLink>
                                                ))
                                            }
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-lg-4 col-md-4 col-sm-4 col-xs-12 adress_cont">
                                        <div className="information_bottom text_align_center">
                                        <div className="icon_bottom"> <i className="fa fa-user" aria-hidden="true"></i> </div>
                                        <div className="info_cont">
                                        {
                                            contact.map((data, index) => (
                                            <NavLink key={`phone-link${index}`} className="mb-3" to={`telto:${data.contact_number}`}>
                                                <h5>{data.contact_title}</h5>
                                                <h4>+91-{data.contact_number}</h4>
                                            </NavLink>
                                            ))
                                        }
                                            <p>Mon-Fri 8:30am-6:30pm</p>
                                        </div>
                                        </div>
                                    </div>
                                    <div className="col-lg-4 col-md-4 col-sm-4 col-xs-12 adress_cont">
                                        <div className="information_bottom text_align_center">
                                        <div className="icon_bottom"> <i className="fa fa-envelope" aria-hidden="true"></i> </div>
                                        <div className="info_cont">
                                        {
                                            <NavLink to={`mailto:${profile.company_email}`} className="" >{profile.company_email}</NavLink>
                                        }
                                            <p>24/7 online support</p>
                                        </div>
                                        </div>
                                    </div>
                                    </div>
                                    <div className="col-lg-12 col-md-12 col-sm-12 col-xs-12 contant_form">
                                        <h2 className="text_align_center">SEND MESSAGE</h2>
                                        <div className="form_section">
                                            <ContactForm/>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                </div>
        </React.Fragment>
    )
}