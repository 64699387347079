import React from "react";
import Slider from "../Section/Slider";
import HeadSection from "../Section/HeadSection";
import ServiceSection from "../Section/ServiceSection";
import Product from "../Section/ProductSection";
import Feedback from "../Section/Feedback";
import Blog from "../Section/blog/Blog";
import Testimonial from "../Section/Testimonial";
import Request from "../Section/RequestSection";
import Clients from "../Section/Clients";



export default function Home(){

    return(
        <React.Fragment>
            <Slider/>
            <HeadSection/>
            <ServiceSection/>
            <Product/>
            <Feedback/>
            <Blog/>
            <Testimonial/>
            <Request/>
            <Clients/>
        </React.Fragment>
    )
}