// src/redux/aboutSlice.js
import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import { API_URL } from '../../VariableHandler/handler';
import axios from 'axios';



// Async action to fetch data
export const fetchSliderData = createAsyncThunk('slider/fetchSliderData', async () => {
  const response = await axios.get(`${API_URL}/slider`);
  const data = response.data;
  // console.log({data});
  return data;
});

// Create a slice
const sliderSlice = createSlice({
  name: 'slider',
  initialState: {
    data: [],
    loading: false,
    error: null,
  },
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(fetchSliderData.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(fetchSliderData.fulfilled, (state, action) => {
        state.loading = false;
        state.data = action.payload;
      })
      .addCase(fetchSliderData.rejected, (state, action) => {
        state.loading = false;
        state.error = action.error.message;
      });
  },
});

export default sliderSlice.reducer;
