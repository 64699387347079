import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { fetchServiceProccessData } from "../../redux/slice/serviceProccesSlice";
import { BASE_URL } from "../../VariableHandler/handler";
import { Col, Container, Row } from "react-bootstrap";



export default function ServiceSection(){

    const dispatch = useDispatch();
    const { data: serviceproccess, loading, error} = useSelector((state) => state.serviceproccess);

    useEffect(() => {
        dispatch(fetchServiceProccessData());
    }, [dispatch]);

    if(loading) return <p>{loading}</p>
    if(error) return <p>Error: {error}</p>
    

    return(
        <div className="section padding_layout_1 light_silver gross_layout">
            <Container>
                <Row>
                    <Col md="12">
                        <div className="full">
                            <div className="main_heading text_align_left">
                                <h2>Service Process</h2>
                                <p className="large">Delivering an extensive range of solutions for all your IT needs.</p>
                            </div>
                        </div>
                    </Col>
                </Row>
                <Row>
                    <Col md="8">
                        <Row>
                            {
                                serviceproccess.map((cat, index) => (
                                <Col key={`service-card${index}`} sm="4" md="4" >
                                    <div className="full">
                                        <div className="service_blog_inner">
                                            <div className="icon text_align_left"><img src={`${BASE_URL}/uploads/icon/${cat.icon}`} alt="#" /></div>
                                            <h4 className="service-heading">{cat.name}</h4>
                                            <p>{cat.content}</p>
                                        </div>
                                    </div>
                                </Col>
                                ))
                            }
                        </Row>
                    </Col>
                </Row>
            </Container>
        </div>
    )
}