import React, { useEffect } from "react";
import InnerBanner from "../Section/InnerBanner";
import { Container, Row, Col } from "react-bootstrap";
import { BASE_URL } from "../../VariableHandler/handler";
import { NavLink } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { fetchServiceData } from "../../redux/slice/serviceSlice";


export default function ServiceList(){

    const dispatch = useDispatch();
    const {data: service} = useSelector((state) => state.service);

    useEffect(() => {
        dispatch(fetchServiceData());
    }, [dispatch]);


    return(
        <React.Fragment>
            <InnerBanner title="Service List"/>
            <div className="section padding_layout_1 service_list">
                <Container>
                    <Row>
                    {
                        service?.data.map((data,index) => (
                            <Col key={`catelist-${index}`} md="4" className="service_blog margin_bottom_50">
                                <div className="full">
                                    <div className="service_img"> <img className="img-responsive" src={`${BASE_URL}/uploads/service/sm/${data.image}`} alt="#" /> </div>
                                    <div className="service_cont">
                                        <h3 className="service_head">{data.name}</h3>
                                        <p>{data.description.slice(0,95)} ...</p>
                                        <div className="bt_cont"> <NavLink className="btn sqaure_bt" to={`/service-detail/${data.id}`}>View Service</NavLink> </div>
                                    </div>
                                </div>
                            </Col>
                        ))
                    }
                    </Row>
                </Container>
            </div>
        </React.Fragment>
    )
}