
import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { fetchSliderData } from "../../redux/slice/sliderSlice";
import { BASE_URL } from "../../VariableHandler/handler";
import '../Style/slider.css';
import ReactOwlCarousel from "react-owl-carousel";
import 'owl.carousel/dist/assets/owl.carousel.css';
import 'owl.carousel/dist/assets/owl.theme.default.css';


const SliderComponent = () => {
    
  const dispatch = useDispatch();
  const { data: slider, loading, error } = useSelector((state) => state.slider);

  useEffect(() => {
    dispatch(fetchSliderData());
  }, [dispatch])

  if(loading) return <p>Loading...</p>;
  if(error) return <p>Error : {error}</p>;

    const responsiveOptions = {
        0: {
            items: 1, // Show 1 card at 0px and up
        },
        500: {
            items: 1, // Show 2 cards at 500px and up
        },
        1000: {
            items: 1, // Show 3 cards at 1000px and up
        },
    };  
  
    return (
        <section id="slider" className="section">
            <div className="">
                <ReactOwlCarousel className="owl-theme" responsive={responsiveOptions} dots={false} nav={true} autoPlay={true} autoplaySpeed={2000} loop={true}>
                {
                    slider.map((data, index) => (
                        <div key={`slider-${index}`} className="slider-image">
                            <img src={`${BASE_URL}/uploads/slider/${data.image}`} alt={data.id} />
                            <div className="content">
                                
                            </div>
                        </div>
                    ))
                }
                </ReactOwlCarousel>
            </div>
        </section>
    );
};
  
export default SliderComponent;