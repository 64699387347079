import React, { useEffect, useState } from "react";
import { NavLink } from "react-router-dom";
import InnerBanner from "../Section/InnerBanner";
import { Col, Container, Row } from "react-bootstrap";
import Testimonial from "../Section/Testimonial";
import Request from "../Section/RequestSection";
import Clients from "../Section/Clients";
import Search from "../Section/blog/Search";
import { BASE_URL, monthHash, timeHash } from "../../VariableHandler/handler";
import { useDispatch, useSelector } from "react-redux";
import { fetchBlogData } from "../../redux/slice/blogSlice";

export default function Blog() {
    const dispatch = useDispatch();
    const { data: blog } = useSelector((state) => state.blog);
    const [inputChange, setInputChange] = useState('');
    const [currentPage, setCurrentPage] = useState(1);
    const [postsPerPage] = useState(2); // You can adjust the number of posts per page here

    useEffect(() => {
        dispatch(fetchBlogData());
    }, [dispatch]);

    const formateDate = (dateString) => {
        const date = dateString;
        const day = date.slice(8, 10);
        const month = date.slice(5, 7);
        const monthString = monthHash(month);
        const year = date.slice(0, 4);
        const time = date.slice(11, 16);
        const timeString = timeHash(time);
        return `${day} ${monthString} ${year} - ${timeString}`;
    };

    // Filtering blog posts based on user input
    const filteredBlog = blog.filter((data) => 
        data.title.toLowerCase().includes(inputChange.toLowerCase()) ||
        data.author.toLowerCase().includes(inputChange.toLowerCase()) ||
        data.description.toLowerCase().includes(inputChange.toLowerCase())
    );

    // Pagination logic
    const indexOfLastPost = currentPage * postsPerPage;
    const indexOfFirstPost = indexOfLastPost - postsPerPage;
    const currentPosts = filteredBlog.slice(indexOfFirstPost, indexOfLastPost);
    const totalPages = Math.ceil(filteredBlog.length / postsPerPage);

    const handlePageChange = (pageNumber) => {
        setCurrentPage(pageNumber);
    };

    return (
        <React.Fragment>
            <InnerBanner title="Blog" />
            <section className="section padding_layout_1 blog_list">
                <Container>
                    <Row>
                        <Col sm="12" xs="12" md="8" lg="8" className="pull-right">
                            <div className="full">
                                {currentPosts.length === 0 ? (
                                    <p>No blog posts found.</p>
                                ) : (
                                    currentPosts.map((data, index) => (
                                        <div key={`blog-${index}`} className="blog_section">
                                            <div className="blog_feature_img">
                                                <img className="img-responsive" src={`${BASE_URL}/uploads/blog/sm/${data.image}`} alt="#" />
                                            </div>
                                            <div className="blog_feature_cantant">
                                                <p className="blog_head">{data.title}</p>
                                                <div className="post_info">
                                                    <ul>
                                                        <li><i className="fa fa-user" aria-hidden="true"></i> {data.author}</li>
                                                        <li><i className="fa fa-calendar" aria-hidden="true"></i> {formateDate(data.created_at)}</li>
                                                    </ul>
                                                </div>
                                                <p>{data.description.slice(0, 300)}...</p>
                                                <div className="bottom_info">
                                                    <div className="pull-left">
                                                        <NavLink className="btn sqaure_bt" to={`/blog-detail/${data.id}`}>Read More<i className="fa fa-angle-right"></i></NavLink>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    ))
                                )}
                                <div className="center">
                                    <ul className="pagination style_1">
                                        <li onClick={() => handlePageChange(currentPage - 1)} disabled={currentPage === 1}>
                                            <NavLink to="#"><i className="fa fa-angle-left" aria-hidden="true"></i></NavLink>
                                        </li>
                                        {Array.from({ length: totalPages }, (_, index) => (
                                            <li key={index} className={currentPage === index + 1 ? 'active' : ''} onClick={() => handlePageChange(index + 1)}>
                                                <NavLink to="#">{index + 1}</NavLink>
                                            </li>
                                        ))}
                                        <li onClick={() => handlePageChange(currentPage + 1)} disabled={currentPage === totalPages}>
                                            <NavLink to="#"><i className="fa fa-angle-right" aria-hidden="true"></i></NavLink>
                                        </li>
                                    </ul>
                                </div>
                            </div>
                        </Col>
                        <Col xs="12" sm="12" md="4" lg="4" className="pull-left">
                            <div className="side_bar">
                                <div className="side_bar_blog">
                                    <h4>SEARCH</h4>
                                    <Search onChange={(value) => setInputChange(value)} />
                                </div>
                                <div className="side_bar_blog">
                                    <h4>ABOUT BLOG</h4>
                                    <p>Here's we cover topics or news about computer repair, fire safety, CCTV appliances, and accounting software:</p>
                                </div>
                                <div className="side_bar_blog">
                                    <h4>RECENT POST</h4>
                                    <div className="recent_post">
                                        <ul>
                                            {blog.map((data, index) => (
                                                <li key={`recent-post-${index}`}>
                                                    <p className="post_head"><NavLink to={`/blog-detail/${data.id}`}>{data.title}</NavLink></p>
                                                    <p className="post_date"><i className="fa fa-calendar" aria-hidden="true"></i> {formateDate(data.created_at)}</p>
                                                </li>
                                            ))}
                                        </ul>
                                    </div>
                                </div>
                            </div>
                        </Col>
                    </Row>
                </Container>
            </section>
            <Testimonial />
            <Request />
            <Clients />
        </React.Fragment>
    );
}
