// src/redux/aboutSlice.js
import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import { API_URL } from '../../VariableHandler/handler';
import axios from 'axios';



// Async action to fetch data
export const fetchProductData = createAsyncThunk('product/fetchProductData', async () => {
  const response = await axios.get(`${API_URL}/product`);
  const data = response.data;
  // console.log({data});
  return data;
});



// Create a slice
const productSlice = createSlice({
  name: 'product',
  initialState: {
    data: {data: []},
    loading: false,
    error: null,
  },
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(fetchProductData.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(fetchProductData.fulfilled, (state, action) => {
        state.loading = false;
        state.data = action.payload;
      })
      .addCase(fetchProductData.rejected, (state, action) => {
        state.loading = false;
        state.error = action.error.message;
      });
  },
});

export default productSlice.reducer;


