import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { fetchClientData } from "../../redux/slice/clientSlice";
import Loader from "../Elements/Loader";
import { BASE_URL } from "../../VariableHandler/handler";


export default function Clients(){

    const dispatch = useDispatch();
    const {data: client, loading, error} = useSelector((state) => state.client);

    useEffect(() => {
        dispatch(fetchClientData());
    }, [dispatch]);

    if(loading) return <Loader/>
    if(error) return <p>Error: {error}</p>

    return(
        <div className="section padding_layout_1" style={{padding: "50px 0"}}>
            <div className="container">
                <div className="row">
                    <div className="col-md-12">
                        <div className="full">
                            <ul className="brand_list">
                                {
                                    client.map((data,index) => (
                                        <li key={`client-${index}`}><img src={`${BASE_URL}/uploads/client/${data.image}`} alt={data.title} /></li>
                                    ))
                                }
                            </ul>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}   