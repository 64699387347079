import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link, NavLink } from "react-router-dom";
import { fetchAddressData } from "../../redux/slice/addressSlice";
import { fetchContactData } from "../../redux/slice/contactSlice";
import { fetchServiceData } from "../../redux/slice/serviceSlice";



export default function Footer(){

    const dispatch = useDispatch();

    const {data: address, loading, error} = useSelector((state) => state.address);
    const {data: service } = useSelector((state) => state.service);
    const {data: contact } = useSelector((state) => state.contact);

    useEffect(() => {
        dispatch(fetchAddressData());
        dispatch(fetchContactData());
        dispatch(fetchServiceData());
    },[dispatch])

    // Shuffle and get 5 random whychoose items
    const getRandomService = (service) => {
        return [...service?.data].sort(() => 0.5 - Math.random()).slice(0, 5);
    }

    if(loading) return <p>Loading...</p>
    if(error) return <p>Error: {error}</p>
        
    const randomService = getRandomService(service);

    return(
        <footer className="footer_style_2">
            <div className="container-fuild">
                <div className="row">
                <div className="map_section">
                    <iframe src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d1740.0764491190953!2d77.12674692160735!3d28.874549742238777!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x390dab719fe36add%3A0x315f0be3bfa9be34!2sApporva%20Infotech%20Solutions%20Pvt.%20Ltd.!5e1!3m2!1sen!2sin!4v1725429210978!5m2!1sen!2sin" width="500" height="700" style={{border:"0"}} allowFullScreen="" loading="lazy" referrerPolicy="no-referrer-when-downgrade"></iframe>
                </div>
                <div className="footer_blog">
                    <div className="row">
                        <div className="col-md-6">
                            <div className="main-heading left_text">
                            <h2>Apoorva Infotech</h2>
                            </div>
                            <p>
                                Welcome to Apoorva Infotech Solutions, your trusted partner for innovative IT solutions. Founded
                                with a passion for technology and a vision to drive digital transformation, we specialize in delivering
                                cutting-edge IT services that empower businesses to thrive in an ever-evolving digital landscape.
                            </p>
                            <ul className="social_icons">
                                <li className="social-icon fb"><NavLink  to="#"><i className="fa fa-facebook" aria-hidden="true"></i></NavLink></li>
                                <li className="social-icon tw"><NavLink  to="#"><i className="fa fa-twitter" aria-hidden="true"></i></NavLink></li>
                                <li className="social-icon gp"><NavLink  to="#"><i className="fa fa-google-plus" aria-hidden="true"></i></NavLink></li>
                            </ul>
                        </div>
                        <div className="col-md-6">
                            <div className="main-heading left_text">
                            <h2>Additional links</h2>
                            </div>
                            <ul className="footer-menu">
                            <li><NavLink  to={"about"}><i className="fa fa-angle-right"></i> About us</NavLink></li>
                            <li><NavLink  to="it_term_condition.html"><i className="fa fa-angle-right"></i> Terms and conditions</NavLink></li>
                            <li><NavLink  to="it_privacy_policy.html"><i className="fa fa-angle-right"></i> Privacy policy</NavLink></li>
                            <li><NavLink  to={"blog"}><i className="fa fa-angle-right"></i> Blog</NavLink></li>
                            <li><NavLink  to={"contact"}><i className="fa fa-angle-right"></i> Contact us</NavLink></li>
                            </ul>
                        </div>
                        <div className="col-md-6">
                            <div className="main-heading left_text">
                                <h2>Services</h2>
                            </div>
                            <ul className="footer-menu">
                            {
                                randomService.map((data, index) => (
                                    <li key={`service-${index}`}><NavLink  to={`/service-detail/${data.id}`}><i className="fa fa-angle-right"></i> {data.name}</NavLink></li>

                                ))
                            }
                            </ul>
                        </div>
                        <div className="col-md-6">
                            <div className="main-heading left_text">
                                <h2>Contact us</h2>
                            </div>
                            <p>
                            {
                                address.map((data, index) => (
                                    <Link key={`address-${index}`} to={data.googlemap_link} className="text-secondary mb-3" target="_blank">
                                        <span className="text-white mb-2">{data.address_title}: </span> <br /> 
                                        {data.address} {data.city} - {data.pincode}<br/>
                                        {data.state}
                                    </Link>
                                ))  
                            }
                            </p>
                            {
                                contact.map((data, index) => (
                                    <p key={`contact-${index}`} style={{fontSize:"18px"}}><NavLink  to={`tel:91-${data.contact_number}`}>+91-{data.contact_number}</NavLink></p>
                                ))  
                            }
                        </div>
                    </div>
                </div>
                <div className="cprt">
                    <p>Apoorva infotech © Copyrights 2019 Design by Indutech IT Solution Pvt. Ltd.</p>
                </div>
                </div>
            </div>
        </footer>
    )
}