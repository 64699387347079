import React, { useState } from 'react'
import { useDispatch } from 'react-redux'
import { submitContactForm } from '../../../redux/slice/contactFormSlice';
import { toast, ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';


export default function ContactForm() {

    const dispatch = useDispatch();
    const [formData, setFormData] = useState({
        first_name: '',
        last_name: '',
        email: '',
        mobile: '',
        message: '',
    });

    const [errors, setErrors] = useState({});
    const [isSubmitting, setIsSubmitting] = useState(false);

    // Validate field 

    const Validate = () => {
        const newErrors = {};

        if (!formData.first_name.trim()) newErrors.first_name = 'First name is required';
        if (!formData.last_name.trim()) newErrors.last_name = 'Last name is required';
        if (!formData.email.trim()) {
          newErrors.email = 'Email is required';
        } else if (!/\S+@\S+\.\S+/.test(formData.email)) {
          newErrors.email = 'Email address is invalid';
        }
       // Mobile number validation for 10 digits
        if (!formData.mobile.trim()) {
            newErrors.mobile = 'Phone number is required';
        } else if (!/^\d{10}$/.test(formData.mobile)) {
            newErrors.mobile = 'Phone number must be exactly 10 digits';
        }

        if (!formData.message.trim()) newErrors.message = 'Message is required';
    
        setErrors(newErrors);
        return Object.keys(newErrors).length === 0;
    }

  

    const handleChange = (e) => {
        const { name, value } = e.target;
        setFormData((prevData) => ({
          ...prevData,
          [name]: value
        }));
    };

    const handleSubmit = async (e) => {
        e.preventDefault();
    
        // Check validation
        if (!Validate()) {
            return;
        }
    
        setIsSubmitting(true);
    
        try {
            // Dispatch the action and unwrap the response
            const response = await dispatch(submitContactForm(formData)).unwrap();
    
            if (response.success) {
                // Show success message from API response
                toast.success(response.message || 'Form submitted successfully!');
    
                // Reset form after successful submission
                setFormData({
                    first_name: '',
                    last_name: '',
                    email: '',
                    mobile: '',
                    message: ''
                });
            } else {
                // Show error message from API response
                toast.error(response.message || 'Failed to submit the form.');
            }
        } catch (error) {
            // Check for validation errors
            if (error.response && error.response.status === 422) {
                const validationErrors = error.response.data.errors;
                Object.values(validationErrors).forEach((message) => toast.error(message));
            } else {
                // Show a generic error message for other types of errors
                const errorMessage = error.message || 'Failed to submit the form. Please try again.';
                toast.error(errorMessage);
            }
        } finally {
            setIsSubmitting(false);
        }
    };

    return (
        <React.Fragment>
            
            <form className="form_contant" onSubmit={handleSubmit} >
                <fieldset>
                    <div className="row">
                        <div className="field col-lg-6 col-md-6 col-sm-12 col-xs-12">
                            <input className="field_custom" name="first_name" placeholder="First Name*" type="text" value={formData.first_name} onChange={handleChange}/>
                            {errors.first_name && <p className="text-danger mb-3">{errors.first_name}</p>}
                        </div>
                        <div className="field col-lg-6 col-md-6 col-sm-12 col-xs-12">
                            <input className="field_custom" name="last_name" placeholder="Last name*" type="text" value={formData.last_name} onChange={handleChange}/>
                            {errors.last_name && <p className="text-danger mb-3">{errors.last_name}</p>}
                        </div>
                        <div className="field col-lg-6 col-md-6 col-sm-12 col-xs-12">
                            <input className="field_custom" name="email" placeholder="Email adress*" type="email" value={formData.email} onChange={handleChange}/>
                            {errors.email && <p className="text-danger mb-3">{errors.email}</p>}
                        </div>
                        <div className="field col-lg-6 col-md-6 col-sm-12 col-xs-12">
                            <input className="field_custom" name="mobile" placeholder="Phone number*" type="text" value={formData.mobile} onChange={handleChange}/>
                            {errors.mobile && <p className="text-danger mb-3">{errors.mobile}</p>}
                        </div>
                        <div className="field col-lg-12 col-md-12 col-sm-12 col-xs-12">
                            <textarea className="field_custom" name="message" placeholder="Messager*" value={formData.message} onChange={handleChange}></textarea>
                            {errors.message && <p className="text-danger mb-3">{errors.message}</p>}
                        </div>
                        <div className="center"><button type="submit"  className="btn main_bt">{isSubmitting ? 'Submitting...' : 'SUBMIT NOW'}</button></div>
                    </div>
                </fieldset>
            </form>
            <ToastContainer position="bottom-left"/>
        </React.Fragment>
    )
}
