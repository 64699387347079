import React, { useEffect, useState } from "react";
import InnerBanner from "../Section/InnerBanner";
import { Col, Container, Row } from "react-bootstrap";
import { NavLink, useParams } from "react-router-dom";
import Team from "../Section/Team";
import { useDispatch, useSelector } from "react-redux";
import { fetchServiceData } from "../../redux/slice/serviceSlice";
import { BASE_URL } from "../../VariableHandler/handler";
import RecentBlog from "../Section/blog/RecentBlog";


export default function ServiceDetail(){

    const { id } = useParams();

    const [serviceDetail, setServiceDetail] = useState({});
    const dispatch = useDispatch();
    const {data: service} = useSelector((state) => state.service);

    useEffect(() => {
        dispatch(fetchServiceData(id));
    }, [dispatch, id]);


    useEffect(() => {
        if (service) {
            const detail = service?.data.find(post => post.id === parseInt(id));
            setServiceDetail(detail);
        }
    }, [service, id]);

    
    return(
        <React.Fragment>
            <InnerBanner title={serviceDetail.name}/>
            <div className="section padding_layout_1 service_list">
                <Container>
                    <Row>
                        <Col md="9">
                            <Row style={{marginBottom: "30px;"}}>
                                <Col md="12" className="service_blog margin_bottom_50">
                                    <div className="full">
                                        <div className="service_img"> <img className="img-responsive" src={`${BASE_URL}/uploads/service/md/${serviceDetail.image}`} alt={serviceDetail.name} /> </div>
                                        <div className="service_cont">
                                            <h3 className="service_head">{serviceDetail.name}</h3>
                                            <p>{serviceDetail.description}</p>
                                        </div>
                                    </div>
                                </Col>
                            </Row>
                            <Team/>
                        </Col>
                        <Col md="3">
                            <div className="side_bar">
                                <div className="side_bar_blog">
                                    <h4>GET A QUOTE</h4>
                                    <p>Get in touch with us for a customized quote on our products or services. We provide tailored assistance to meet your specific requirements.</p>
                                    <NavLink className="btn sqaure_bt" to={`/quote/${serviceDetail.id}/service`}>Send Quote</NavLink> 
                                </div>
                                <RecentBlog/>
                            </div>
                        </Col>
                        {/* {
                            service.map((data, index) => (
                                <Col key={`servieCard-${index}`} md="3" className="service_blog margin_bottom_50">
                                    <div className="full">
                                        <div className="service_img"> <img className="img-responsive" src={`${BASE_URL}/uploads/service/sm/${data.image}`} alt="#" /> </div>
                                        <div className="service_cont">
                                            <h3 className="service_head">{data.name}</h3>
                                            <p>{data.description.slice(0,95)} ...</p>
                                            <div className="bt_cont"> <NavLink className="btn sqaure_bt" to={`/service-detail/${data.id}`}>View Service</NavLink> </div>
                                        </div>
                                    </div>
                                </Col>
                            ))
                        } */}
                    </Row>
                </Container>
            </div>
            
        </React.Fragment>
    )
}