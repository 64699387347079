import React from "react";


export default function AboutSection(){

    return(
        <div className="section padding_layout_1 light_silver gross_layout">
            <div className="container">
                <div className="row">
                    <div className="col-sm-12 col-md-8">
                        <div className="full">
                            <div className="main_heading text_align_left">
                                <h2>Why Choose Us?</h2>
                                <p className="medium">We are more than just an IT solutions provider — We are your technology
                                partner, committed to driving your success through innovation and reliable service.</p>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="row">
                    <div className="col-md-8">
                        <div className="row">
                            <div className="col-md-6">
                                <div className="full">
                                    <div className="service_blog_inner2">
                                        <div className="icon text_align_left"><i className="fa fa-certificate"></i></div>
                                        <h4 className="service-heading">Expertise & Experience</h4>
                                        <p>Our team brings years of experience and in-depth knowledge across
                                        various industries.</p>
                                    </div>
                                </div>
                            </div>
                            <div className="col-md-6">
                                <div className="full">
                                    <div className="service_blog_inner2">
                                        <div className="icon text_align_left"><i className="fa fa-lightbulb-o"></i></div>
                                        <h4 className="service-heading">Innovation at the Core</h4>
                                        <p>In an ever-changing technological landscape, we are always one step
                                        ahead, bringing the latest innova􀆟ons to our clients.</p>
                                    </div>
                                </div>
                            </div>
                            <div className="col-md-6">
                                <div className="full">
                                    <div className="service_blog_inner2">
                                        <div className="icon text_align_left"><i className="fa fa-history"></i></div>
                                        <h4 className="service-heading">24/7 Support</h4>
                                        <p>We are committed to providing round-the-clock support, ensuring your
                                        business stays up and running at all times.</p>
                                    </div>
                                </div>
                            </div>
                            <div className="col-md-6">
                                <div className="full">
                                    <div className="service_blog_inner2">
                                        <div className="icon text_align_left"><i className="fa fa-cogs"></i></div>
                                        <h4 className="service-heading">Customer-Centric Approach</h4>
                                        <p>We work closely with our clients, understanding their specific
                                        challenges and cra􀅌ing solu􀆟ons that meet their unique needs.</p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}