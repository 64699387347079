import React from "react";
import { Breadcrumb } from "react-bootstrap";
import { NavLink } from "react-router-dom";

export default function InnerBanner({title}){

    return(
        <div id="inner_banner" className="section inner_banner_section">
            <div className="container">
                <div className="row">
                    <div className="col-md-12">
                        <div className="full">
                            <div className="title-holder">
                                <div className="title-holder-cell text-left">
                                    <h1 className="page-title">{title}</h1>
                                    <Breadcrumb>
                                        <Breadcrumb.Item><NavLink to={"/"} className="text-white">Home</NavLink></Breadcrumb.Item>
                                        <Breadcrumb.Item>{title}</Breadcrumb.Item>
                                    </Breadcrumb>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}   