import React from 'react';
import { useNavigate } from 'react-router-dom';

const EmptySection = (props) => {

    const navigate = useNavigate();

    const handleback = () => {
        navigate(-1);
    }
    
    return (
        <div className="section padding_layout_1">
            <div className="container">
                <div className="row">
                    <div className="col-md-12">
                        <div className="full">
                            <div className="main_heading text_align_center">
                                <h2>{props.title}</h2>
                                <p className="large">Sorry 🙇.</p>
                            </div>
                        </div>
                    </div>
                </div>
                
                <div className="w-100 text-center">
                    <button onClick={handleback} className="btn sqaure_bt">Back</button>
                </div>
            </div>
        </div>
    );
}

export default EmptySection;
