import React, { useEffect } from "react";
import { Container, Row, Col } from "react-bootstrap";
import { NavLink } from "react-router-dom";
import { BASE_URL } from "../../../VariableHandler/handler";
import { useDispatch, useSelector } from "react-redux";
import { fetchServiceData } from "../../../redux/slice/serviceSlice";


export default function ServiceCategory(){

    const dispatch = useDispatch();
    const {data: service} = useSelector((state) => state.service);

    useEffect(() => {
        dispatch(fetchServiceData());
    }, [dispatch]);

    // Function to shuffle an array
    const shuffleArray = (array) => {
        return array.sort(() => Math.random() - 0.5);
    };

    // Shuffle the categoryList and take the first 3 items
    const randomCategories = shuffleArray([...service?.data]).slice(0, 3);

    return(
        <div className="section padding_layout_1 light_silver service_list">
            <Container>
                <Row>
                    {
                        randomCategories.slice(0,3).map((data, index) => (
                            <Col key={index} md="4" className="my-5 service_blog">
                                <div className="full">
                                    <div className="service_img"> <img className="img-responsive" src={`${BASE_URL}/uploads/service/sm/${data.image}`} alt="#" /> </div>
                                    <div className="service_cont">
                                        <h3 className="service_head">{data.name}</h3>
                                        <p>{data.description.slice(0,96)} ...</p>
                                        <div className="bt_cont"> <NavLink className="btn sqaure_bt" to={`/service-detail/${data.id}`}>View Service</NavLink> </div>
                                    </div>
                                </div>
                            </Col>
                        ))
                    }
                </Row>
            </Container>
        </div>
    )
}