import React from "react";
import { Routes, Route, HashRouter } from "react-router-dom";
import Header from "./Components/Include/Header";
import Footer from "./Components/Include/Footer";
import Home from "./Components/Pages/Home";
import About from "./Components/Pages/About";
import Contact from "./Components/Pages/Contact";
import Service from "./Components/Pages/Service";
import ServiceList from "./Components/Pages/ServiceList";
import ServiceDetail from "./Components/Pages/ServiceDetail";
import Blog from "./Components/Pages/Blog";
import BlogDetail from "./Components/Pages/BlogDetail";
import ScrollOnTop from "./Components/Section/ScrollOnTop";
import Products from "./Components/Pages/Products";
import ProductDetail from "./Components/Pages/ProductDetail";
import CategoryDetail from "./Components/Section/Home/CategoryDetail";
import QuotePage from "./Components/Pages/QuotePage";





function App() {


  return (
      <React.Fragment>
          <HashRouter>
            <ScrollOnTop/>
            <Header/>
            <Routes>
              <Route path="/" element={<Home/>}/> 
              <Route path="about" element={<About/>}/>  
              <Route path="product" element={<Products/>}/>  
              <Route path="product-detail/:id" element={<ProductDetail/>}/>  
              <Route path="service" element={<Service/>}/>  
              <Route path="service-list" element={<ServiceList/>}/>
              <Route path="service-detail/:id" element={<ServiceDetail/>}/>
              <Route path="blog" element={<Blog/>}/>
              <Route path="blog-detail/:id" element={<BlogDetail/>}/>
              <Route path="contact" element={<Contact/>}/>
              <Route path="category-detail/:id" element={<CategoryDetail/>}/>  
              <Route path="quote/:id/:type" element={<QuotePage/>}/>
            </Routes> 
            <Footer/>
          </HashRouter>
      </React.Fragment>
  );
}

export default App;
