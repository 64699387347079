import React, { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux';
import { fetchBlogData } from '../../../redux/slice/blogSlice';
import { monthHash, timeHash } from '../../../VariableHandler/handler';
import { NavLink } from 'react-router-dom';

export default function RecentBlog() {

    const dispatch = useDispatch();
    const {data: blog } = useSelector((state) => state.blog);

    useEffect(() => {
        dispatch(fetchBlogData());
    }, [dispatch])

    const formateDate = (dateString) => {
        const date = dateString;
        const day = date.slice(8,10);
        const month = date.slice(5,7);
        const monthString = monthHash(month);
        const year = date.slice(0,4);
        const time = date.slice(11,16);
        const timeString = timeHash(time);
        // console.log(timeString);
        return `${monthString} ${day}, ${year} - ${timeString}`;
    }


    // Function to shuffle an array
    const shuffleArray = (array) => {
        return array.sort(() => Math.random() - 0.5);
    };

    // Shuffle the categoryList and take the first 3 items
    const randomBlog = shuffleArray([...blog]).slice(0, 4);


  return (
        <div className="side_bar_blog">
            <h4>RECENT BLOG</h4>
            <div className="recent_post">
                <ul>
                    {
                        randomBlog.map((data, index) => (
                        <li key={`recent-blog-${index}`} className="">
                            <p className="post_head"><NavLink to={`/blog-detail/${data.id}`}>{data.title}</NavLink></p>
                            <p className="post_date"><i className="fa fa-calendar" aria-hidden="true"></i>{""} {formateDate(data.created_at)}</p>
                        </li>
                        ))
                    }
                    
                </ul>
            </div>
        </div>
  )
}
