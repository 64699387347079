import React from "react";


export default function Search({onChange}){

    

    const handleSearch = (e) => {
        const typeInput = e.target.value;
        onChange(typeInput);
    }

    return(
        <React.Fragment>
            <div className="side_bar_search">
                <div className="input-group stylish-input-group">
                    <input className="form-control" placeholder="Search"  onChange={handleSearch} type="text"/>
                    <span className="input-group-addon">
                    <button type="submit"><i className="fa fa-search" aria-hidden="true"></i></button>
                    </span> 
                </div>
            </div>
        </React.Fragment>
    )
}