import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { API_URL } from "../../VariableHandler/handler";

export const submitQuoteForm = createAsyncThunk(
    'quoteForm/submitQuoteForm',
    async (formDataToSend) => {
        const response = await fetch(`${API_URL}/quote-request`, {
            method: 'POST',
            body: formDataToSend
        });

        if (!response.ok) {
            const errorResponse = await response.json();
            throw new Error(errorResponse.message || 'Failed to submit the quote form');
        }

        return response.json();
    }
);

const quoteFormSlice = createSlice({
    name: 'quote',
    initialState: {
        status: 'idle',
        error: null
    },
    reducers: {},
    extraReducers: (builder) => {
        builder
            .addCase(submitQuoteForm.pending, (state) => {
                state.status = 'loading';
            })
            .addCase(submitQuoteForm.fulfilled, (state) => {
                state.status = 'succeeded';
            })
            .addCase(submitQuoteForm.rejected, (state, action) => {
                state.status = 'failed';
                state.error = action.error.message;
            });
    }
});

export default quoteFormSlice.reducer;
