import React from "react";
import InnerBanner from "../Section/InnerBanner";
import ServiceSection from "../Section/ServiceSection";
import Category from "../Section/service/service-cat";
import Team from "../Section/Team";
import Request from "../Section/RequestSection";
import Testimonial from "../Section/Testimonial";
import Clients from "../Section/Clients";


export default function Service(){

    

    return(
        <React.Fragment>
            <InnerBanner title="Service"/>
            <ServiceSection/>
            <Category/>
            <Testimonial/>
            <Request/>
            <Clients/> 
        </React.Fragment>
    )
}