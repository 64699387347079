import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { fetchWhyChooseData } from "../../redux/slice/whyChooseSlice";
import { BASE_URL } from "../../VariableHandler/handler";
import { Link, NavLink } from "react-router-dom";
import { Col, Row } from "react-bootstrap";



export default function HeadSection(){

    const dispatch = useDispatch();
    const {data: whychoose } = useSelector((state) => state.whychoose);

    useEffect(() => {
        dispatch(fetchWhyChooseData());
    }, [dispatch])


    return(
        <div className="section padding_layout_1">
            <div className="container">
                <div className="row">
                    <div className="col-md-12">
                        <div className="full">
                            <div className="main_heading text_align_center">
                                <h2>What We Do</h2>
                                <p className="large">We provide a comprehensive suite of IT solutions tailored to meet the unique needs of businesses,
                                regardless of their size or industry</p>
                            </div>
                        </div>
                    </div>
                </div>
                <Row id="lg-slider">
                    {
                        whychoose?.data.map((data,index) => (
                            <Col key={`whychoose-${index}`} xs="12" sm="6" md="4" lg="3" className="mt-5">
                                <NavLink to={`/category-detail/${data.id}`} className="full text_align_center margin_bottom_30 hover-card">
                                    <div className="center">
                                        <div className="icon"> <img src={`${BASE_URL}/uploads/icon/${data.icon}`} alt="#" /> </div>
                                    </div>
                                    <div className="cat-link">
                                        <h4 className="theme_color">{data.name}</h4>
                                    </div>
                                    <p>{data.content}</p>
                                </NavLink>
                            </Col>
                        ))
                    }
                </Row>
              
            </div>
        </div>
    )
}