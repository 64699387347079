import React, { useEffect, useState } from "react";
import InnerBanner from "../Section/InnerBanner";
import { Col, Container, Row } from "react-bootstrap";
import { NavLink, useParams } from "react-router-dom";
import Team from "../Section/Team";
import Request from "../Section/RequestSection";
import Clients from "../Section/Clients";
import { useDispatch, useSelector } from "react-redux";
import { fetchProductData } from "../../redux/slice/productSlice";
import Loader from "../Elements/Loader";
import { BASE_URL } from "../../VariableHandler/handler";
import RecentBlog from "../Section/blog/RecentBlog";


export default function ProductDetail(){

    const { id } = useParams();
    const dispatch = useDispatch();
    const [productDetail, setProductDetail] = useState({});
    const { data: product, loading, error } = useSelector((state) => state.product);
    
    useEffect(() => {
        dispatch(fetchProductData(id));
    }, [dispatch, id]);

    useEffect(() => {
        if (product && product?.data.length > 0) {
            const postId = +id; 
            console.log({id, product})
            const detail = product?.data.find(post => post.id === postId);
            setProductDetail(detail || null);
            console.log("Detail", detail);
        }
    }, [product, id]);

    // Extract categories with unique IDs
    // const productCategory = product?.data.map(product => product.category) // Extract the category objects
    // .filter((category, index, self) => // Filter out duplicates
    //     index === self.findIndex(cat => cat.id === category.id)
    // );

    if (loading) return <Loader />;
    if (error) return <p>Error: {error}</p>;

    return(
        <React.Fragment>
            <InnerBanner title={productDetail.name}/>
            <div className="section padding_layout_1 service_list">
                <Container>
                    <Row>
                        <Col md="9">
                            <Row style={{marginBottom: "30px;"}}>
                                <Col md="12" className="service_blog margin_bottom_50">
                                    <div className="full">
                                        <div className="service_img">
                                        {
                                            productDetail && productDetail.image ? <img className="img-responsive" src={`${BASE_URL}/uploads/product/md/${productDetail.image}`} alt={productDetail.name} /> : "Loading..."
                                        }
                                        </div>
                                        <div className="service_cont">
                                            <h3 className="service_head">{productDetail.name}</h3>
                                            <p>{productDetail.description}</p>
                                            <div className="bt_cont"></div>
                                        </div>
                                    </div>
                                </Col>
                               
                            </Row>
                        
                            <Team/>
                        </Col>
                        <Col md="3">
                            <div className="side_bar">
                                
                                <div className="side_bar_blog">
                                    <h4>GET A QUOTE</h4>
                                    <p>Get in touch with us for a customized quote on our products or services. We provide tailored assistance to meet your specific requirements.</p>
                                    <NavLink className="btn sqaure_bt" to={`/quote/${productDetail.id}/product`}>Send Quote</NavLink> 
                                </div>
                                <RecentBlog/>
                                
                            </div>
                        </Col>
                    </Row>
                </Container>
            </div>
            
            <Request/>
            <Clients/>
        </React.Fragment>
    )
}