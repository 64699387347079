// src/redux/aboutSlice.js
import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import { API_URL } from '../../VariableHandler/handler';
import axios from 'axios';



// Async action to fetch data
export const fetchWhyChooseData = createAsyncThunk('whychoose/fetchWhyChooseData', async () => {
  const response = await axios.get(`${API_URL}/category-whychoose`);
  const data = response.data;
  // console.log({data});
  return data;
});

// Create a slice
const whyChooseSlice = createSlice({
  name: 'whychoose',
  initialState: {
    data: {data: []},
    loading: false,
    error: null,
  },
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(fetchWhyChooseData.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(fetchWhyChooseData.fulfilled, (state, {payload}) => {
        state.loading = false;
        state.data = payload;
      })
      .addCase(fetchWhyChooseData.rejected, (state, action) => {
        state.loading = false;
        state.error = action.error.message;
      });
  },
});

export default whyChooseSlice.reducer;
