import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { API_URL } from "../../VariableHandler/handler";




export const submitContactForm = createAsyncThunk(
    'contactForm/submitContactForm',
    async (formData) => {
        const response = await fetch(`${API_URL}/contact-form`, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json'
            },
            body: JSON.stringify(formData)
        });

        if(!response.ok){
            throw new Error('Failed to submit the contact form');
        }

        return response.json();
    }
);


const contactFormSlice = createSlice({
    name: 'contactForm',
    initialState: {
        status: 'idle',
        error: null
    },
    reducers: {},

    extraReducers: (builder) => {
        builder
            .addCase(submitContactForm.pending, (state) => {
                state.status = 'loading';
            })
            .addCase(submitContactForm.fulfilled, (state) => {
                state.status = 'succeeded';
            })
            .addCase(submitContactForm.rejected, (state, action) => {
                state.status = 'failed';
                state.error = action.error.message;
            });
    }

});

export default contactFormSlice.reducer;