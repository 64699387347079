import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { fetchAboutData } from "../../../redux/slice/aboutSlice";
import { BASE_URL } from "../../../VariableHandler/handler";
import Loader from "../../Elements/Loader";

export default function Chooseus(){

    const dispatch = useDispatch();
    const { data: about, loading, error } = useSelector((state) => state.about);

    useEffect(() => {
        dispatch(fetchAboutData());
    }, [dispatch]);

    if (loading) return <Loader/>;
    if (error) return <p>Error: {error}</p>;
    
    

    return(
        <div className="section padding_layout_1">
            <div className="container">
                <div className="row">
                    <div className="col-md-12">
                        <div className="full">
                            <div className="main_heading text_align_center">
                                <h2>About us</h2>
                                <p className="medium">
                                    Welcome to Apoorva Infotech Solutions, your trusted partner for innovative IT solutions. Founded
                                    with a passion for technology and a vision to drive digital transformation, we specialize in delivering
                                    cuting-edge IT services that empower businesses to thrive in an ever-evolving digital landscape.
                                </p>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="row about_blog">
                    <div className="col-lg-6 col-md-6 col-sm-12 about_cont_blog">
                        <div className="full text_align_left">
                        {/* <h3>What we do</h3> */}
                        <h3>{about ? about.title : "What we do"}</h3>
                        <p>
                        {
                            about ? about.content : "Lorem Ipsum is simply dummy text of the printing and type setting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley.."
                        }
                        </p>
                        <ul className="about-list">
                            <li><i className="fa fa-circle"></i>
                                <span>
                                   <b>IT Infrastructure & Support:</b> Ensuring seamless and secure operations with robust
                                    infrastructure solutions.
                                </span>
                            </li>
                            <li><i className="fa fa-circle"></i>
                                <span>
                                    <b>Cloud Solutions:</b>  Offering scalable and flexible cloud services to enhance efficiency and
                                    collaboration.
                                </span>
                            </li>
                            <li><i className="fa fa-circle"></i>
                                <span>
                                    <b>Cybersecurity:</b> Safeguarding your business from cyber threats with advanced security
                                    strategies.
                                </span>
                            </li>
                            <li><i className="fa fa-circle"></i>
                                <span>
                                    <b>Custom Software Development:</b> Creating tailored software solutions that drive productivity
                                    and innovation.
                                </span>
                            </li>
                            <li><i className="fa fa-circle"></i>
                                <span>
                                    <b>IT Consulting:</b> Providing strategic insights to help your business stay ahead in a fast-changing
                                    tech landscape.
                                </span>
                            </li>

                        </ul>
                        </div>
                    </div>
                    <div className="col-lg-6 col-md-6 col-sm-12 about_feature_img padding_right_0">
                        <div className="full text_align_center"> <img className="img-responsive" src={about && about.image ? `${BASE_URL}/uploads/about/${about.image}` : "assets/images/it_service/post-06.jpg"} alt="#" /> </div>
                    </div>
                </div>
                
            </div>
        </div>
    )
}