import { configureStore } from "@reduxjs/toolkit";
import aboutSlice from "../slice/aboutSlice";
import sliderSlice from "../slice/sliderSlice";
import whyChooseSlice from "../slice/whyChooseSlice";
import blogSlice from "../slice/blogSlice";
import serviceProccesSlice from "../slice/serviceProccesSlice";
import counterSlice from "../slice/counterSlice";
import addressSlice from "../slice/addressSlice";
import contactSlice from "../slice/contactSlice";
import profileSlice from "../slice/profileSlice";
import teamSlice from "../slice/teamSlice";
import clientSlice from "../slice/clientSlice";
import productSlice from "../slice/productSlice";
import contactFormSlice from "../slice/contactFormSlice";
import serviceSlice from "../slice/serviceSlice";
import testimonialSlice from "../slice/testimonialSlice";
import categorySlice from "../slice/categorySlice";
import quoteSlice from "../slice/quoteSlice";





const store = configureStore({
    reducer: {
        about: aboutSlice,
        address: addressSlice,
        blog: blogSlice,
        categoryDetail: categorySlice,
        client: clientSlice,
        contact: contactSlice,
        contactForm: contactFormSlice,
        counter: counterSlice,
        profile: profileSlice,
        product: productSlice,
        quote: quoteSlice,
        service: serviceSlice,
        slider: sliderSlice,
        team: teamSlice,
        testimonial: testimonialSlice,
        whychoose: whyChooseSlice,
        serviceproccess: serviceProccesSlice
    },
});

export default store;