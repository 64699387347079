import React, { useEffect } from "react";
import { BASE_URL } from "../../VariableHandler/handler";
import { useDispatch, useSelector } from "react-redux";
import { fetchTestimonialData } from "../../redux/slice/testimonialSlice";
import Loader from "../Elements/Loader";
import styled from "styled-components";
import ReactOwlCarousel from "react-owl-carousel";
import 'owl.carousel/dist/assets/owl.carousel.css';
import 'owl.carousel/dist/assets/owl.theme.default.css';



export default function Testimonial(){
    
   
    const dispatch = useDispatch();
    const {data: testimonial, loading, error} = useSelector((state) => state.testimonial);

   


    useEffect(() => {
        dispatch(fetchTestimonialData());
        
   
        
    }, [dispatch]);


    if(loading) return <Loader/>
    if(error) return <p>Error: {error}</p>
    
    // console.log(testimonial);


    const responsiveOptions = {
        0: {
            items: 1, // Show 1 card at 0px and up
        },
        500: {
            items: 1, // Show 2 cards at 500px and up
        },
        1000: {
            items: 1, // Show 3 cards at 1000px and up
        },
    };  
    
    

    return(
        <div className="section padding_layout_1 testmonial_section white_fonts">
            <div className="container">
                <div className="row">
                    <div className="col-md-12">
                        <div className="full">
                            <div className="main_heading text_align_left">
                                <h2 style={{textTransform: "none"}}>What Clients Say?</h2>
                                <p className="large">Here are testimonials from clients..</p>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="row">
                    <div className="col-sm-7">
                        <div className="full">
                            <Carousel >
                                <ReactOwlCarousel className="owl-theme" responsive={responsiveOptions} dots={false} autoPlay={true} autoplaySpeed={2000} loop={true}>
                                    {
                                        testimonial.map((data, index) => (
                                            <CarouselItem key={`testimonial-${index}`} className='item'>
                                                <div className="test-photo"> <img src={`${BASE_URL}/uploads/testimonial/${data.image}`} className="img-responsive" alt="testimonial_image" width="150" height="150"/> </div>
                                                <div className="test-container">
                                                    <div className="test-content">{data.message}</div>
                                                    <div className="test-meta">
                                                        <h4>{data.name}</h4>
                                                        <span className="test-position">{`${data.designation}, ${data.company_name}`}</span>
                                                    </div>
                                                </div>
                                            </CarouselItem>
                                        ))
                                    }
                                </ReactOwlCarousel>
                                {/* Controls */}
                               
                            </Carousel>
                        </div>
                    </div>
                    
                </div>
            </div>
        </div>
    )
}


const Carousel = styled.div`
    position: relative;
    padding-right: 70px;
`;

const CarouselIndicator = styled.div`
    position: absolute;
    right: 0;
    bottom: -45px;
    left: 0;
    z-index: 15;
    dislay: flex;
    -webpack-box-pack: center;
    justify-content: flex-start;
    padding-left: 0;
    margin-right: 0;
    margin-left: 0;
    list-style: none;
    margin-bottom:0;

    & li{
        display: inline-block;
        width: 15px;
        height: 15px;
        margin: 0 5px;
        text-indent: -999px;
        cursor: pointer;
        border: none;
        border-radius: 50%;
        background: #d5d6d6;
    }
    & li.active{
        width: 35px;
        border-radius: 20px;
        background-color: red;
    }
`;

const CarouselInner = styled.div`
    position: relative;
    width: 100%;
    overflow: hidden;
`;


const CarouselItem = styled.div`
    position: relative;
    diplay: block;
    -webkit-box-align: center;
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: start;

    & .test-photo{
        width: 30%;
        margin-right: 2em;
    }
    & .test-photo img{
        width: 100%;
        aspect-ratio: 1/1;
        border-radius: 50%;
    }

    & .test-container .test-content{
        height: 100%;
    }

    & .test-container .test-content .test-meta h4{
        margin: 30px 0 10px 0
    }

    @media (max-width: 991px) {
      flex-direction: column;
      align-items: center;

      & .test-photo{
        width: 25%;
        margin-right: 0em;
        margin-bottom: 2em;
      }
      & .test-container{
        text-align: center;
      }
      & .test-container .test-content .test-meta h4{
        margin: 10px 0 5px 0
      }
    }
`