import React from "react";
import InnerBanner from "../Section/InnerBanner";
import Chooseus from "../Section/about/Chooseus";
import AboutSection from "../Section/about/AboutService";
import Team from "../Section/Team";
import Testimonial from "../Section/Testimonial";
import Request from "../Section/RequestSection";
import Clients from "../Section/Clients";



export default function About(){
    

    return(
        <React.Fragment>
            <InnerBanner title="About us"/>
            <Chooseus/>
            <AboutSection/>
            <Team/>
            <Testimonial/>
            <Request/>
            <Clients/>
        </React.Fragment>
    )
}