import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { NavLink } from "react-router-dom";
import { fetchProfileData } from "../../redux/slice/profileSlice";
import { BASE_URL } from "../../VariableHandler/handler";
import Loader from "../Elements/Loader";

export default function Header() {
    const [scrollPosition, setScrollPosition] = useState(0);
    const [isHeaderFixed, setIsHeaderFixed] = useState(false);
    const [isMenuOpen, setIsMenuOpen] = useState(false);

    const dispatch = useDispatch();
    const {data: profile, loading, error} = useSelector((state) => state.profile);

    const handleScroll = () => {
        const position = window.pageYOffset;
        setScrollPosition(position);
        if (position > 300) {
            setIsHeaderFixed(true);

        } else {
            setIsHeaderFixed(false);
        }
    };

    const toggleMenu = () => {
        setIsMenuOpen(!isMenuOpen);
    };

    useEffect(() => {
        dispatch(fetchProfileData());

        window.addEventListener('scroll', handleScroll, { passive: true });

        return () => {
            window.removeEventListener('scroll', handleScroll);
        };
    }, [dispatch]);

    if(loading) return <Loader/>
    if(error) return <p>Error: {error}</p>
    
    

    return (
        <React.Fragment>
            <header id="default_header" className={`header_style_1 ${isHeaderFixed ? 'header_fixed shadow scroll-active' : ''}`}>
                {/* header top */}
                <div className={`header_top ${isHeaderFixed ? 'hide_topbar' : ''}`}>
                    <div className="container">
                        <div className="row">
                            <div className="col-md-8">
                                <div className="full">
                                    <div className="topbar-left">
                                        <ul className="list-inline">
                                            <li>
                                                <span className="topbar-label">
                                                    <i className="fa fa-mobile"></i>
                                                </span>
                                                <span className="topbar-hightlight">
                                                    <NavLink to={`tel:91-${profile.whatsapp}`}>{profile.whatsapp}</NavLink>
                                                </span>
                                            </li>
                                            <li>
                                                <span className="topbar-label">
                                                    <i className="fa fa-envelope-o"></i>
                                                </span>
                                                <span className="topbar-hightlight">
                                                    <NavLink to={`mailto:${profile.company_email}`}>{profile.company_email}</NavLink>
                                                </span>
                                            </li>
                                        </ul>
                                    </div>
                                </div>
                            </div>
                            <div className="col-md-4 right_section_header_top">
                                <div className="float-right">
                                    <div className="social_icon">
                                        <ul className="list-inline social-list">
                                            <li><NavLink className="fa fa-facebook" to={profile.company_fb_link} title="Facebook" target="_blank"></NavLink></li>
                                            <li><NavLink className="fa fa-google-plus" to={`mailto:${profile.company_email}`} title="Google+" target="_blank"></NavLink></li>
                                            <li><NavLink className="fa fa-twitter" to={profile.company_twitter_link} title="Twitter" target="_blank"></NavLink></li>
                                            <li><NavLink className="fa fa-linkedin" to={profile.company_linkedin_link} title="LinkedIn" target="_blank"></NavLink></li>
                                            <li><NavLink className="fa fa-instagram" to={profile.company_instagram_link} title="Instagram" target="_blank"></NavLink></li>
                                        </ul>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                {/* end header top */}
                
                {/* header bottom */}
                <div className="header_bottom shadow">
                    <div className="container">
                        <div className="row">
                            <div className="col-lg-3 col-md-12 col-sm-12 col-xs-12">
                                {/* logo start */}
                                <div className="logo"> 
                                    <NavLink to={"/"}>
                                        <img src={`${BASE_URL}/uploads/profile/${profile.company_logo}`} alt="logo" />
                                    </NavLink> 
                                </div>
                                {/* logo end */}
                            </div>
                            <div className="col-lg-9 col-md-12 col-sm-12 col-xs-12">
                                {/* menu start */}
                                <div className="menu_side">
                                    <div id="navbar_menu" className={`${isMenuOpen ? 'open' : ''}`}>
                                        <ul className="first-ul">
                                            <li><NavLink to={"/"}>Home</NavLink></li>
                                            <li><NavLink to={'about'}>About Us</NavLink></li>
                                            <li> 
                                                <NavLink to={"service"}>Service</NavLink>
                                                <ul>
                                                    <li><NavLink to={"service-list"}>Services list</NavLink></li>
                                                </ul>
                                            </li>
                                            <li><NavLink to={"blog"}>Blog</NavLink></li>
                                            <li><NavLink to={"contact"}>Contact</NavLink></li>
                                        </ul>
                                    </div>
                                    <div id="menu-button" onClick={toggleMenu}>
                                        <span className="menu-icon"><i className="fa fa-bars"></i></span>
                                    </div>
                                </div>
                                {/* menu end */}
                            </div>
                        </div>
                    </div>
                </div>
                {/* header bottom end */}
            </header>
        </React.Fragment>
    );
}
