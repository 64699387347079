import React, { useEffect, useState } from 'react';
import InnerBanner from '../Section/InnerBanner';
import QuoteForm from './QuoteForm';
import { useParams } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { fetchServiceProccessData } from '../../redux/slice/serviceProccesSlice';
import { fetchProductData } from '../../redux/slice/productSlice';
import { fetchCategoryDetailData } from '../../redux/slice/categorySlice';
import Loader from '../Elements/Loader';

const QuotePage = () => {
    
    const { id, type } = useParams();
    const [data, setData] = useState(null); 
    const [getTitle, setGetTitle] = useState(null); 

    const dispatch = useDispatch();
    const { data: service } = useSelector((state) => state.service);
    const { data: categoryDetail } = useSelector((state) => state.categoryDetail);
    const { data: product } = useSelector((state) => state.product);

    useEffect(() => {
        if (type === 'service') {
            dispatch(fetchServiceProccessData(id));
        } else if (type === 'product') {
            dispatch(fetchProductData(id));
        } else if (type === 'category') {
            dispatch(fetchCategoryDetailData(id));
        }
    }, [id, type, dispatch]);

    // Service find 
    useEffect(() => {
        if (type === 'service' && service?.data) {
            const detail = service.data.find((item) => item.id === +id);
            if (detail) {
                setData(detail);
                setGetTitle(detail.name);
            }
        }
    }, [service, type, id]);

    // Product find 
    useEffect(() => {
        if (type === 'product' && product?.data) {
            const detail = product?.data.find((item) => item.id === +id);
            if (detail) {
                setData(detail);
                setGetTitle(detail.name);
            }
        } 
    }, [product, type, id]);

    // Category find 
    useEffect(() => {
        if (type === 'category' && categoryDetail?.data) {
            const detail = categoryDetail.data.find(item => item.category_id === +id);
            if (detail) {
                setData(detail);
                setGetTitle(detail.category?.name);
            }
        }
    }, [categoryDetail, type, id]);
    

    return (
        <React.Fragment>
            <InnerBanner title={getTitle ? getTitle : 'Loading...'} />
            {
                data ? <QuoteForm type={type} data={data} /> : <Loader/>
            }
        </React.Fragment>
    );
};

export default QuotePage;
