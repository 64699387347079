import React from "react";
import { NavLink } from "react-router-dom";

export default function Request(){

    return(
        <div className="section">
            <div className="container">
                <div className="row">
                    <div className="col-md-12">
                        <div className="full">
                            <div className="contact_us_section">
                                <div className="call_icon"> <img src="assets/images/it_service/phone_icon.png" alt="#" /> </div>
                                <div className="inner_cont">
                                <h2>REQUEST A FREE QUOTE</h2>
                                <p>Get answers and advice from people you want it from.</p>
                                </div>
                                <div className="button_Section_cont"> <NavLink className="btn dark_gray_bt" to={"/contact"}>Contact us</NavLink> </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}