import React, { useEffect, useState } from 'react'
import InnerBanner from '../InnerBanner'
import { NavLink, useParams } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import Loader from '../../Elements/Loader';
import RecentBlog from '../blog/RecentBlog';
import { Col, Row } from 'react-bootstrap';
import Team from '../Team';
import { BASE_URL } from '../../../VariableHandler/handler';
import { fetchCategoryDetailData } from '../../../redux/slice/categorySlice';
import EmptySection from '../EmptySection';

export default function CategoryDetail() {

    const { id } = useParams();

    const [getTitle, setGetTitle] = useState("");
    const [getData, setGetData] = useState({});

    const dispatch = useDispatch();
    const { data: categoryDetail, loading } = useSelector((state) => state.categoryDetail);

    useEffect(() => {
        dispatch(fetchCategoryDetailData());
    }, [dispatch])


    useEffect(() => {

        if (categoryDetail?.data.length) {
            console.log("CAtegory__ID: ", id);
            const dataFound = categoryDetail.data.find(post => post.category_id === +id);
            if (dataFound) {
                setGetTitle(dataFound.category.name);
                setGetData(dataFound);
                console.log(id);
            } else {
                console.log("No matching category found");
            }
        }
    }, [categoryDetail, id]);


    // Show loader while loading
    if (loading) return <Loader />;

    // If no data is found, show an appropriate message
    if (!getTitle) return <EmptySection title="Category Detail Not Found!" />;

    return (
        <React.Fragment>
            <InnerBanner title={getTitle} />
            <div className="section padding_layout_1 service_list">
                <div className='container'>
                    <Row>
                        <Col md="9">
                            <Row style={{ marginBottom: "30px;" }}>
                                <Col md="12" className="service_blog margin_bottom_50">
                                    <div className="full">
                                        <div className="service_img"> 
                                        {
                                            getData && getData.image ? <img className="img-responsive" src={`${BASE_URL}/uploads/category/${getData.image}`} alt={getData.category.category} /> : 'Loading..'
                                        }
                                        </div>
                                        <div className="service_cont">
                                            <h3 className="service_head">{getData.category.category}</h3>
                                            <div className='cat-description' dangerouslySetInnerHTML={{__html: getData.description}} />
                                            <div className="bt_cont"></div>
                                        </div>
                                    </div>
                                </Col>
                            </Row>
                            <Team />
                        </Col>
                        <Col md="3">
                            <div className="side_bar">

                                <div className="side_bar_blog">
                                    <h4>GET A QUOTE</h4>
                                    <p>Get in touch with us for a customized quote on our products or services. We provide tailored assistance to meet your specific requirements.</p>
                                    <NavLink className="btn sqaure_bt" to={`/quote/${getData.category_id}/category`}>Send Quote</NavLink>
                                </div>
                                <RecentBlog />
                               
                            </div>
                        </Col>
                    </Row>
                </div>
            </div>
        </React.Fragment>
    )
}
