import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { NavLink } from "react-router-dom";
import { fetchBlogData } from "../../../redux/slice/blogSlice";
import { BASE_URL, monthHash, timeHash } from "../../../VariableHandler/handler";
import ReactOwlCarousel from "react-owl-carousel";
import 'owl.carousel/dist/assets/owl.carousel.css';
import 'owl.carousel/dist/assets/owl.theme.default.css';


export default function Blog(){

    const dispatch = useDispatch();
    const { data: blog } = useSelector((state) => state.blog);

    useEffect(() => {
        dispatch(fetchBlogData());
    }, [dispatch])

    const formateDate = (dateString) => {
        const date = dateString;
        const day = date.slice(8,10);
        const month = date.slice(5,7);
        const monthString = monthHash(month);
        const year = date.slice(0,4);
        const time = date.slice(11,16);
        const timeString = timeHash(time);
        // console.log(timeString);
        return `${monthString} ${day}, ${year} - ${timeString}`;
    }

    const responsiveOptions = {
        0: {
            items: 1, // Show 1 card at 0px and up
        },
        500: {
            items: 2, // Show 2 cards at 500px and up
        },
        1000: {
            items: 3, // Show 3 cards at 1000px and up
        },
    };  

    return (
        <div className="section padding_layout_1">
            <div className="container">
                <div className="row">
                    <div className="col-md-12">
                        <div className="full">
                            <div className="main_heading text_align_left">
                                <h2>Latest from Our Blog</h2>
                            </div>
                        </div>
                    </div>
                </div>
                <ReactOwlCarousel className="owl-theme" responsive={responsiveOptions} dots={false} autoPlay={true} autoplaySpeed={2000} loop={true}>
                {
                    blog.map((data, index) => (
                    <div key={`blog-${index}`} className="item mx-2">
                        <div className="full blog_colum">
                            <div className="blog_feature_img"> <img src={`${BASE_URL}/uploads/blog/sm/${data.image}`} alt={data.title} /> </div>
                            <div className="post_time">
                                <p><i className="fa fa-clock-o"></i>{""} {formateDate(data.created_at)}</p>
                            </div>
                            <div className="blog_feature_head">
                                <h4>{data.title}</h4>
                            </div>
                            <div className="blog_feature_cont">
                                <p>{data.description.slice(0,121)} ... <NavLink to={`/blog-detail/${data.id}`} className="text-danger">read more</NavLink></p>
                            </div>
                        </div>
                    </div>
                    ))
                }
                </ReactOwlCarousel>
            </div>
            </div>
    )
}