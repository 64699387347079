import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { fetchCounterData } from "../../redux/slice/counterSlice";


export default function Feedback(){

    const dispatch = useDispatch();
    const { data: counter, loading, error } = useSelector((state) => state.counter);

    useEffect(() => {
        dispatch(fetchCounterData());
    }, [dispatch])

    if(loading) return <p>Loading...</p>
    if(error) return <p>Error: {error}</p>

    

    return(
        <div className="section padding_layout_1 light_silver gross_layout right_gross_layout">
            <div className="container">
                <div className="row">
                    <div className="col-md-12">
                        <div className="full">
                            <div className="main_heading text_align_right">
                                <h2>Our Feedback</h2>
                                <p className="large">Easy and effective way to get your device repaired.</p>
                            </div>
                        </div>
                    </div>
                    </div>
                    <div className="row counter">
                    <div className="col-md-4"> </div>
                    <div className="col-md-8">
                        <div className="row">
                            <div className="col-lg-6 col-md-6 col-sm-6 col-xs-12 margin_bottom_50">
                                <div className="text_align_right"><i className="fa fa-smile-o"></i></div>
                                <div className="text_align_right">
                                    <p className="counter-heading text_align_right">Happy Customers</p>
                                </div>
                                <h5 className="counter-count">{counter.happy_customers ?? "285"}</h5>
                            </div>
                            <div className="col-lg-6 col-md-6 col-sm-6 col-xs-12 margin_bottom_50">
                                <div className="text_align_right"><i className="fa fa-laptop"></i></div>
                                <div className="text_align_right">
                                    <p className="counter-heading text_align_right">Laptop Repaired</p>
                                </div>
                                <h5 className="counter-count">{ counter.laptop_repair ?? "8132"}</h5>
                            </div>
                            <div className="col-lg-6 col-md-6 col-sm-6 col-xs-12 margin_bottom_50">
                                <div className="text_align_right"><i className="fa fa-desktop"></i></div>
                                <div className="text_align_right">
                                    <p className="counter-heading">Computer Repaired</p>
                                </div>
                                <h5 className="counter-count">{ counter.computer_repair ?? "824"}</h5>
                            </div>
                            <div className="col-lg-6 col-md-6 col-sm-6 col-xs-12 margin_bottom_50">
                                <div className="text_align_right"><i className="fa fa-windows"></i></div>
                                <div className="text_align_right">
                                    <p className="counter-heading">OS Installed</p>
                                </div>
                                <h5 className="counter-count">{counter.os_installed ?? "738"}</h5>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}