// src/redux/aboutSlice.js
import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import { API_URL } from '../../VariableHandler/handler';
import axios from 'axios';



// Async action to fetch data
export const fetchAddressData = createAsyncThunk('address/fetchAddressData', async () => {
  const response = await axios.get(`${API_URL}/company-address`);
  return response.data;
});

// Create a slice
const addressSlice = createSlice({
  name: 'address',
  initialState: {
    data: [],
    loading: false,
    error: null,
  },
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(fetchAddressData.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(fetchAddressData.fulfilled, (state, action) => {
        state.loading = false;
        state.data = action.payload;
      })
      .addCase(fetchAddressData.rejected, (state, action) => {
        state.loading = false;
        state.error = action.error.message;
      });
  },
});

export default addressSlice.reducer;
