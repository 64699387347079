import React, { useEffect } from "react";
import { NavLink } from "react-router-dom";
import { BASE_URL, staff } from "../../VariableHandler/handler";
import { useDispatch, useSelector } from "react-redux";
import { fetchTeamData } from "../../redux/slice/teamSlice";
import Loader from "../Elements/Loader";

export default function Team(){

    const dispatch = useDispatch();
    const {data: team, loading, error} = useSelector((state) => state.team);

    useEffect(() => {
        dispatch(fetchTeamData());
    }, [dispatch])

    if(loading) return <Loader/>
    
    return(
        <div className="section padding_layout_1">
            <div className="container">
                <div className="row">
                    <div className="col-md-12">
                        <div className="full">
                        <div className="main_heading text_align_left">
                            <h2>Experienced Staff</h2>
                            <p className="large">Our experts have been featured in press numerous times.</p>
                        </div>
                        </div>
                    </div>
                </div>
                <div className="row">
                {
                    team.map((data, index) => (
                        <div key={`staff-${index}`} className="col-md-3 col-sm-6">
                            <div className="full team_blog_colum">
                                <div className="it_team_img"> <img className="img-responsive" src={`${BASE_URL}/uploads/profile/${data.photo}`} alt={data.name}/> </div>
                                <div className="team_feature_head">
                                    <h4>{data.name}</h4>
                                    <p className="mb-2">{data.designation}</p>
                                </div>
                                <div className="team_feature_social">
                                    <div className="social_icon">
                                        <ul className="list-inline">
                                            <li><NavLink className="fa fa-facebook" to={data.facebook} title="Facebook" target="_blank"></NavLink></li>
                                            <li><NavLink className="fa fa-google-plus" to={data.gmail} title="Google+" target="_blank"></NavLink></li>
                                            <li><NavLink className="fa fa-twitter" to={data.twitter} title="Twitter" target="_blank"></NavLink></li>
                                            <li><NavLink className="fa fa-linkedin" to={data.linkedin} title="LinkedIn" target="_blank"></NavLink></li>
                                            <li><NavLink className="fa fa-instagram" to={data.instagram} title="Instagram" target="_blank"></NavLink></li>
                                        </ul>
                                    </div>
                                </div>
                            </div>
                        </div>
                    ))
                }
                </div>
            </div>
            </div>
    )
}