import React, { useEffect, useState } from "react";
import { BASE_URL } from "../../VariableHandler/handler";
import { useDispatch, useSelector } from "react-redux";
import { fetchProductData } from "../../redux/slice/productSlice";
import { NavLink, useParams } from "react-router-dom";
import Loader from "../Elements/Loader";


export default function Product({categoryName}){

    const { id } = useParams();

    const [category, setCategory] = useState(null);

    const dispatch = useDispatch();
    const {data: product } = useSelector((state) => state.product);

    useEffect(() => {
        dispatch(fetchProductData());
    }, [dispatch])


    useEffect(() => {
        if (product) {
            const detail = product?.data.filter(post => post.category_id === parseInt(id));
            
            setCategory(detail);

            // Set the category name using the prop function if detail exists
            if (detail && detail.category) {
                categoryName(detail.category.category);
            }
        }
    }, [product, id, categoryName]);

     

    if (!category) return <Loader/>;

    return(
        <div className="section padding_layout_1">
            <div className="container">
                <div className="row">
                    <div className="col-md-12">
                        <div className="full">
                            <div className="main_heading text_align_center">
                                <h2>Our Products</h2>
                                <p className="large">We package the products with best services to make you a happy customer.</p>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="row">
                {
                    product?.data.slice(0,8).map((data, index) => (
                        <div key={`product-${index}`} className="col-lg-3 col-md-6 col-sm-6 col-xs-12 margin_bottom_50_all">
                            <div className="product_list">
                                <NavLink to={`/product-detail/${data.id}`} className="product_img">
                                    <img className="img-responsive" src={`${BASE_URL}/uploads/product/sm/${data.image}`} alt={data.name} />
                                </NavLink>
                                <div className="product_detail_btm">
                                    <div className="center">
                                        <h4>
                                            <NavLink to={`/product-detail/${data.id}`}>{data.name}</NavLink>
                                        </h4>
                                    </div>
                                    <div className="product_price">
                                        <p>
                                            {data.description.slice(0, 80)} ...
                                            <NavLink to={`/product-detail/${data.id}`} className="text-danger">
                                                Read more
                                            </NavLink>
                                        </p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    ))
                }
                </div>
                <div className="bt_count text-center">
                    <NavLink to="/product" className="btn sqaure_bt">View All</NavLink>
                </div>
            </div>
        </div>
    )
}