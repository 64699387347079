

export const API_URL = process.env.REACT_APP_API_URL;
export const BASE_URL = process.env.REACT_APP_BASE_URL;

// console.log('URL', process.env.REACT_APP_API_URL);


export function monthHash(month) {
    // Array of short month names
    const monthNames = ["Jan", "Feb", "Mar", "Apr", "May", "Jun", "Jul", "Aug", "Sep", "Oct", "Nov", "Dec"];
    
    // Convert the month to an integer and get the corresponding month name
    return monthNames[parseInt(month, 10) - 1] || "Invalid Month";
};

export function timeHash(inputTime){
    // Convert the input time (HH:MM) to a Date object
    const [inputHours, inputMinutes] = inputTime.split(':').map(Number);
    const inputDate = new Date();
    inputDate.setHours(inputHours, inputMinutes, 0, 0); // Set the input time

    // Get the current time
    const now = new Date();

    // Calculate the difference in seconds between the current time and input time
    const diffInSeconds = Math.floor((now - inputDate) / 1000);
    
    // Calculate the time ago in a readable format
    let timeAgo = "";

    if (diffInSeconds < 60) {
        timeAgo = `${diffInSeconds} second${diffInSeconds !== 1 ? 's' : ''} ago`;
    } else if (diffInSeconds < 3600) {
        const minutes = Math.floor(diffInSeconds / 60);
        timeAgo = `${minutes} minute${minutes !== 1 ? 's' : ''} ago`;
    } else if (diffInSeconds < 86400) {
        const hours = Math.floor(diffInSeconds / 3600);
        timeAgo = `${hours} hour${hours !== 1 ? 's' : ''} ago`;
    } else {
        const days = Math.floor(diffInSeconds / 86400);
        timeAgo = `${days} day${days !== 1 ? 's' : ''} ago`;
    }

    return timeAgo;
}

export const Slider = [
    {
        id: 1,
        image: "slide1.jpg"
    },
    {
        id: 2,
        image: "slide2.jpg"
    },
    {
        id: 3,
        image: "slide3.jpg"
    }
];



// media query theme 
export const theme = {
    colors: {
      primary_color: '#D43647',
      hover_primary_color: '#f7021b',
      main_color: '#454E9F',
    },
    fontFamily: {
      heading: 'Montserrat',
      paragraph: 'Inter',
      highlight: 'Montserrat',
    },
    media: {
      tab: '998px',
      mobile: '767px',
      sm_mobile: '576px',
      xsm_mobile: '372px',
    }
};

// Team array
export const staff = [
    {
        id: 1,
        name: "Dean Michael",
        image: "team-member-1.jpg",
    },
    {
        id: 2,
        name: "Ruby Jake",
        image: "team-member-2.jpg",
    },
    {
        id: 3,
        name: "David Hussay",
        image: "team-member-3.jpg",
    },
    {
        id: 3,
        name: "David Hussay",
        image: "team-member-3.jpg",
    }
]

// Category 
export const categoryList = [
    {
        id: 1,
        image: "post-01.jpg",
        title: "Data Recovery",
        content: "Exerci tation ullamcorper suscipit lobortis nisl ut aliquip ex ea commodo consequat.",
    },
    {
        id: 2,
        image: "post-02.jpg",
        title: "Computer Repair",
        content: "Exerci tation ullamcorper suscipit lobortis nisl ut aliquip ex ea commodo consequat.",
    },
    {
        id: 3,
        image: "post-03.jpg",
        title: "Network Solutions",
        content: "Exerci tation ullamcorper suscipit lobortis nisl ut aliquip ex ea commodo consequat.",
    },
    {
        id: 4,
        image: "post-04.jpg",
        title: "Data Recovery",
        content: "Exerci tation ullamcorper suscipit lobortis nisl ut aliquip ex ea commodo consequat.",
    },
    {
        id: 5,
        image: "post-05.jpg",
        title: "Computer Repair",
        content: "Exerci tation ullamcorper suscipit lobortis nisl ut aliquip ex ea commodo consequat.",
    },
    {
        id: 6,
        image: "post-06.jpg",
        title: "Network Solutions",
        content: "Exerci tation ullamcorper suscipit lobortis nisl ut aliquip ex ea commodo consequat.",
    },
    {
        id: 7,
        image: "post-07.jpg",
        title: "Data Recovery",
        content: "Exerci tation ullamcorper suscipit lobortis nisl ut aliquip ex ea commodo consequat.",
    },
    {
        id: 8,
        image: "post-08.jpg",
        title: "Computer Repair",
        content: "Exerci tation ullamcorper suscipit lobortis nisl ut aliquip ex ea commodo consequat.",
    },
    {
        id: 9,
        image: "post-01.jpg",
        title: "Fire Equipments",
        content: "Consectetur, assumenda provident lorem ipsum dolor sit amet, consectetur adipisicing elit. Quae laboriosam sunt hic perspiciatis, asperiores mollitia excepturi voluptatibus sequi nostrum ipsam veniam omnis nihil! A ea maiores corporis. Lorem ipsum dolor sit amet, consectetur adipisicing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.",
    }
];


export const BlogPost = [
    {
        id: 1,
        image: "post-01.jpg",
        title: "New launch Laptop ",
        author: "John Deo",
        date: "12 Aug 2024",
        tag: "computer",
        content: "Consectetur, assumenda provident lorem ipsum dolor sit amet, consectetur adipisicing elit. Quae laboriosam sunt hic perspiciatis, asperiores mollitia excepturi voluptatibus sequi nostrum ipsam veniam omnis nihil! A ea maiores corporis. Lorem ipsum dolor sit amet, consectetur adipisicing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.",
        socialmedia: {
            facebook: "https://www.facebook.com",
            twitter: "https://x.com",
            gmail: "example@gmail.com",
            linkedin: "https://www.linkedin.com"
        }
    },
    {
        id: 2,
        image: "post-08.jpg",
        title: "Mother board",
        author: "Kapil Dev",
        date: "10 Jul 2024",
        tag: "computer",
        content: "Consectetur, assumenda provident lorem ipsum dolor sit amet, consectetur adipisicing elit. Quae laboriosam sunt hic perspiciatis, asperiores mollitia excepturi voluptatibus sequi nostrum ipsam veniam omnis nihil! A ea maiores corporis. Lorem ipsum dolor sit amet, consectetur adipisicing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.",
        socialmedia: {
            facebook: "https://www.facebook.com",
            twitter: "https://x.com",
            gmail: "example@gmail.com",
            linkedin: "https://www.linkedin.com"
        }
    },
    {
        id: 3,
        image: "post-03.jpg",
        title: "Your work space",
        author: "Shekhar Suman",
        date: "16 Jun 2024",
        tag: "computer",
        content: "Consectetur, assumenda provident lorem ipsum dolor sit amet, consectetur adipisicing elit. Quae laboriosam sunt hic perspiciatis, asperiores mollitia excepturi voluptatibus sequi nostrum ipsam veniam omnis nihil! A ea maiores corporis. Lorem ipsum dolor sit amet, consectetur adipisicing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.",
        socialmedia: {
            facebook: "https://www.facebook.com",
            twitter: "https://x.com",
            gmail: "example@gmail.com",
            linkedin: "https://www.linkedin.com"
        }
    },
    {
        id: 4,
        image: "post-04.jpg",
        title: "How most important work of cooling fan",
        author: "Shekhar Suman",
        date: "18 Jun 2024",
        tag: "computer",
        content: "Consectetur, assumenda provident lorem ipsum dolor sit amet, consectetur adipisicing elit. Quae laboriosam sunt hic perspiciatis, asperiores mollitia excepturi voluptatibus sequi nostrum ipsam veniam omnis nihil! A ea maiores corporis. Lorem ipsum dolor sit amet, consectetur adipisicing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.",
        socialmedia: {
            facebook: "https://www.facebook.com",
            twitter: "https://x.com",
            gmail: "example@gmail.com",
            linkedin: "https://www.linkedin.com"
        }
    }
];


// Testimonial 

export const TestimonialList = [
    {
        id: 1,
        name: "Maria Anderson",
        designation: "CFO",
        company_name: "Tech NY",
        image: "client1.jpg",
        message: "You guys rock! Thank you for making it painless, pleasant and most of all hassle free! I wish I would have thought of it first. I am really satisfied with my first laptop service.",
    },
    {
        id: 2,
        name: "Maria Anderson",
        designation: "CFO",
        company_name: "Tech NY",
        image: "client2.jpg",
        message: "You guys rock! Thank you for making it painless, pleasant and most of all hassle free! I wish I would have thought of it first. I am really satisfied with my first laptop service.",
    },
    {
        id: 3,
        name: "Maria Anderson",
        designation: "CFO",
        company_name: "Tech NY",
        image: "client3.jpg",
        message: "You guys rock! Thank you for making it painless, pleasant and most of all hassle free! I wish I would have thought of it first. I am really satisfied with my first laptop service.",
    },
];


export const Products = [
    {
        id: 1,
        name: "USB Pendrive",
        image: "1.jpg",
        mrp: 1500,
        rate: 250,
        rating: 4
    },
    {
        id: 2,
        name: "Keyboard white",
        image: "2.jpg",
        mrp: 2499,
        rate: 1149,
        rating: 4
    },
    {
        id: 3,
        name: "Mcafee Total Protection Antivirus",
        image: "10.png",
        mrp: 2999,
        rate: 1999,
        rating: 4
    },
    {
        id: 4,
        name: "Dual Speaker",
        image: "4.jpg",
        mrp: 1949,
        rate: 899,
        rating: 4
    },
    {
        id: 5,
        name: "Cordies Cable Management - Off White",
        image: "5.jpg",
        mrp: 1459,
        rate: 859,
        rating: 4
    },
    {
        id: 6,
        name: "Tally Prime Accounting Software",
        image: "9.png",
        mrp: 29550,
        rate: 26550,
        rating: 4
    },
    {
        id: 7,
        name: "Busy Accounting Software",
        image: "busy.png",
        mrp: 25350,
        rate: 18550,
        rating: 4
    },
    {
        id: 8,
        name: "CCTV System",
        image: "cctv.png",
        mrp: 5850,
        rate: 3450,
        rating: 4
    },
    {
        id: 9,
        name: "Biomatric",
        image: "biomatric.png",
        mrp: 2500,
        rate: 1800,
        rating: 4
    },
    {
        id: 10,
        name: "Digital Telephone Intercom System",
        image: "intercom.png",
        mrp: 2500,
        rate: 1800,
        rating: 4
    }

]












