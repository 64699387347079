import React, { useEffect } from "react";
import InnerBanner from "../Section/InnerBanner";
import Request from "../Section/RequestSection";
import Clients from "../Section/Clients";
import Blog from "../Section/blog/Blog";
import { useDispatch, useSelector } from "react-redux";
import { fetchProductData } from "../../redux/slice/productSlice";
import Loader from "../Elements/Loader";
import { BASE_URL } from "../../VariableHandler/handler";
import { NavLink } from "react-router-dom";



export default function Products(){    

    const dispatch = useDispatch();
    const {data: product} = useSelector((state) => state.product);

    useEffect(() => {
        dispatch(fetchProductData());
    }, [dispatch])


    if (!product) return <Loader/>;

    return(
        <React.Fragment>
            <InnerBanner title="Product List"/>
            <div className="section padding_layout_1">
                <div className="container">
                    <div className="row">
                        <div className="col-md-12">
                            <div className="full">
                                <div className="main_heading text_align_center">
                                    <h2>Our Products</h2>
                                    <p className="large">We package the products with best services to make you a happy customer.</p>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="row">
                    {
                        product?.data.map((data, index) => (
                            <div key={`product-${index}`} className="col-lg-3 col-md-6 col-sm-6 col-xs-12 margin_bottom_30_all">
                                <div className="product_list">
                                    <NavLink to={`/product-detail/${data.id}`} className="product_img">
                                        <img className="img-responsive" src={`${BASE_URL}/uploads/product/sm/${data.image}`} alt={data.name} />
                                    </NavLink>
                                    <div className="product_detail_btm">
                                        <div className="center">
                                            <h4>
                                                <NavLink to={`/product-detail/${data.id}`}>{data.name}</NavLink>
                                            </h4>
                                        </div>
                                        <div className="product_price">
                                            <p>
                                                {data.description.slice(0, 80)} ...
                                                <NavLink to={`/product-detail/${data.id}`} className="text-danger">
                                                    Read more
                                                </NavLink>
                                            </p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        ))
                    }
                    </div>
                    
                </div>
            </div>
            <Blog/>
            <Request/>
            <Clients/> 
        </React.Fragment>
    )
}