// src/redux/aboutSlice.js
import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { API_URL } from "../../VariableHandler/handler";
import axios from "axios";

const profile_data = {
  company_email: "info@isofttech.com",
  company_fb_link: "https://www.facebook.com",
  company_instagram_link: "https://www.instagram.com",
  company_linkedin_link: "https://www.linkedin.com",
  company_logo: "KFBe1724217886.webp",
  company_name: "Apoorva Infotech",
  company_twitter_link: "https://twitter.com",
  content: "Apoorva Infotech is a trusted provider of comprehensive technology solutions, specializing in computer repair and sales, CCTV installation, fire safety equipment, and accounting software. The company offers reliable repair services for all types of computers and sells a wide range of high-quality computer hardware. Additionally, Apoorva Infotech provides state-of-the-art CCTV systems for enhanced security and fire safety equipment for protection against potential hazards. They also offer user-friendly accounting software to streamline business finances, ensuring a one-stop shop for all technological and security needs.",
  created_at: "2023-11-06T07:02:41.000000Z",
  id: 1,
  updated_at: "2024-09-04T06:17:46.000000Z",
  whatsapp: "9254029400",
};

// Async action to fetch data
export const fetchProfileData = createAsyncThunk(
  "profile/fetchProfileData",
  async () => {
    const response = await axios.get(`${API_URL}/company-profile`);
    return response.data;
  }
);

// Create a slice
const profileSlice = createSlice({
  name: "profile",
  initialState: {
    data: profile_data,
    loading: false,
    error: null,
  },
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(fetchProfileData.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(fetchProfileData.fulfilled, (state, action) => {
        state.loading = false;
        state.data = action.payload;
      })
      .addCase(fetchProfileData.rejected, (state, action) => {
        state.loading = false;
        state.error = action.error.message;
      });
  },
});

export default profileSlice.reducer;
